.tableContainer{
    overflow: scroll;
    width: 90vw;
}

.table, .table th, .table td{
    border: 1px solid;
    padding: 10px;
}

.table {
    border-radius: 10px;
    margin: 1rem auto;
}

.table thead{
    font-weight: bold;
    cursor: pointer;
    user-select: none;
}

.table tr:nth-child(even){
    background: #e5e5c1;
}

.table tr:nth-child(odd){
    background: #ded9d9;
}

input{
    padding: 10px;
    background: whitesmoke;
    border-radius: 10px;
    outline: none;
    border: none;
    width: 100%;
}

.tableContainer img{
    width: 25px;
    height: 25px;
    vertical-align: middle;
}

