@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');
* {
    padding: 0;
    box-sizing: border-box;
    margin: 0 auto;
}

body{
    font-family: 'Ubuntu', sans-serif;
    text-align: center;
    background: #d9cbcb;
}