.form{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    justify-content: center;
}

.upload{
    background: whitesmoke;
    padding: 10px;
    border-radius: 10px;
    margin: 1rem;
}

@media screen and (min-width: 450px) {
    .form{
        flex-direction: row;
    }
}