.App {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
}

h1 {
    font-size: 2.5rem;
    padding: 1em;
    background: #b95959;
    width: 100%;
    color: whitesmoke;
    font-style: italic;
}

button{
    padding: 0.5rem 1rem;
    color: whitesmoke;
    background: #3574bb;
    border-radius: 10px;
    outline: none;
    font-size: 1.2rem;
    cursor: pointer;
    border: 5px solid #d9cbcb;
}

input[type="file"]::file-selector-button{
    padding: 0.5em 1em;
    color: white;
    background: #568adc;
    border-radius: 10px;
    font-size: 1rem;
    border: 5px solid #d9cbcb;
}

hr {
    height: 2px;
    width: 100%;
}